import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubBanner from '../components/SubBanner';
import BlogListing from '../components/BlogListing';

const BlogArchiveTemplate = ({
  data: {
    datoCmsBlogArchive: { seoMetaTags, title, bannerImage, featuredBlogPosts },
    allDatoCmsBlogCategory: { categories },
    allDatoCmsBlog: { nodes },
  },
}) => (
  <Layout seo={seoMetaTags}>
    <main>
      <SubBanner heading={title} image={bannerImage} />
      <BlogListing items={featuredBlogPosts} featured={true} />
      {nodes.length > 0 && (
        <BlogListing items={nodes} categories={categories} />
      )}
    </main>
  </Layout>
);

export const BlogArchiveTemplateQuery = graphql`
  query BlogArchiveTemplateQuery($featuredBlogs: [String!]) {
    datoCmsBlogArchive {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        ...SubBannerImageFragment
      }
      featuredBlogPosts {
        ...BlogCardFragment
      }
    }
    allDatoCmsBlogCategory {
      categories: nodes {
        title
        slug
      }
    }
    allDatoCmsBlog(
      sort: { order: DESC, fields: meta___firstPublishedAt }
      filter: { id: { nin: $featuredBlogs } }
    ) {
      nodes {
        ...BlogCardFragment
      }
    }
  }
`;

export default BlogArchiveTemplate;
